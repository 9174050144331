<template>
  <div
    v-if="audio"
    class="row"
  >
    <div class="col px-0 text-center">
      <h3 class="font-weight-bold mt-2 w-75 mx-auto">
        {{ audio.title }}
      </h3>
      <img
        :src="
          audio.image
            ? audio.image
            : require('@/assets/images/pages/videos/no-thumbnail.svg')
        "
        class="w-50 mx-auto rounded"
        alt="Audio image"
      >
      <!-- eslint-disable-next-line -->
      <div class="w-75 mx-auto mt-2" v-html="audio.embed" >
      </div>
      <!-- eslint-disable-next-line -->
      <h5 class="w-75 mx-auto mt-2" v-html="audio.body" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    audio() {
      return this.$store.getters['audio/selectedAudio']
    },
  },
  created() {
    this.$store.dispatch('audio/fetchAudioById', this.$route.params.id)
  },
  mounted() {
    const audioristaSdk = document.createElement('script')
    audioristaSdk.setAttribute('src', 'https://storage.googleapis.com/strillo-staging/media/project/sdk/107945b0c2b590207dc54c1562c908206f7b9fe8.js')
    document.body.appendChild(audioristaSdk)
  },
}
</script>
